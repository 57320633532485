import { createContext, useEffect, useState } from "react";
import { V1Service } from "common/api/requests";
import {
  AuthTokens,
  IAuthSession,
  getAuth,
  replaceSessionAccessToken,
  saveAuthSession,
  logout as globalLogout
} from "common/utils/auth";
import { useQueryClient } from "@tanstack/react-query";
import { queueGAEvent } from "@/utils/queue-ga-event";

export interface IAuthContext {
  replaceAuth: (data: AuthTokens) => IAuthSession;
  replaceAccessToken: (accessToken: string) => IAuthSession;
  logout: () => Promise<void>;
  session: IAuthSession | undefined;
  startGuestSession: (props?: { adult_user: boolean }) => Promise<IAuthSession>;
  setIsLoadingSession: (isLoading: boolean) => void;
  isLoadingSession: boolean;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function AuthProvider({ children }) {
  const [session, setSession] = useState<IAuthSession>(() => getAuth());
  const [isLoadingSession, setIsLoadingSession] = useState(false);
  const queryClient = useQueryClient();

  const startGuestSession = async (
    { adult_user }: { adult_user: boolean } = { adult_user: false }
  ) => {
    try {
      setIsLoadingSession(true);
      const res = await V1Service.userManagementGuestUserRegistrationPost({
        data: {
          adult_user
        }
      });

      if (!res) {
        setIsLoadingSession(false);
        return;
      }

      return replaceAuth({
        accessToken: res.access_token,
        refreshToken: res.refresh_token
      });
    } catch (e) {
      console.warn("Unable to start guest session", e);
    } finally {
      setIsLoadingSession(false);
    }
  };

  const replaceAuth = (tokens: AuthTokens) => {
    const session = saveAuthSession(tokens);
    setSession(session);
    return session;
  };

  const replaceAccessToken = (accessToken: string) => {
    const session = replaceSessionAccessToken(accessToken);
    setSession(session);
    return session;
  };

  const logout = async (): Promise<void> => {
    await globalLogout();
    setSession(undefined);
    queryClient.clear();
  };

  useEffect(() => {
    if (session) {
      queueGAEvent("set", "user_properties", {
        adult_user: session.adultUser,
        role: session.role
      });
    }
  }, [session]);

  const values = {
    replaceAuth,
    replaceAccessToken,
    logout,
    session,
    startGuestSession,
    isLoadingSession,
    setIsLoadingSession
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export default AuthContext;
