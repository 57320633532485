import { sendGAEvent } from "@next/third-parties/google";

type GaEvent = any[];

let eventQueueProcessingScheduled = false;

let eventQueue: GaEvent[] = [];

const retries = 5;
let currentTry = 1;

const isDataLayerLoaded = () =>
  typeof window !== "undefined" && typeof window.dataLayer !== "undefined";

const processEventQueue = () => {
  eventQueueProcessingScheduled = false;
  if (currentTry > retries) {
    return;
  }
  if (!isDataLayerLoaded()) {
    eventQueueProcessingScheduled = true;
    currentTry++;
    setTimeout(() => {
      processEventQueue();
    }, 1000);
    return;
  }
  currentTry = 1;
  for (const args of eventQueue) {
    sendGAEvent(...args);
  }
  eventQueue = [];
};

/**
 * Used to queue sends to GA if there is a chance the library might not be loaded yet.
 */
export const queueGAEvent = (...args: any[]) => {
  eventQueue.push(args);
  if (!eventQueueProcessingScheduled) {
    processEventQueue();
  }
};
