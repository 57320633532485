import { V1Service } from "../../api/requests";
import jwtDecode from "jwt-decode";

type AccessTokenData = {
  organization_id?: string;
  organization_role?: "member";
  adult_user: boolean;
  workspace_id: string;
  role: "guest";
  token_id: string;
  token_type: string;
  requesting_user_id: string;
  user_id: string;
  exp: number;
  iat: number;
};

type RefreshTokenData = {
  adult_user: boolean;
  token_id: string;
  token_type: string;
  requesting_user_id: string;
  user_id: string;
  exp: number;
  iat: number;
};

export type AuthTokens = {
  accessToken: string;
  refreshToken: string;
};

export interface IAuthSession {
  role: "guest" | "user" | "admin";
  userId: string;
  workspaceId: string;
  adultUser: boolean;
  organizationId?: string;
  expiresAt: number;
  accessTokenExpiresAt: number;
}

export const getSessionFromTokens = ({ accessToken, refreshToken }: AuthTokens): IAuthSession => {
  const accessTokenData = jwtDecode(accessToken) as AccessTokenData;
  const refreshTokenData = jwtDecode(refreshToken) as RefreshTokenData;
  return {
    role: accessTokenData.role,
    userId: accessTokenData.user_id,
    workspaceId: accessTokenData.workspace_id,
    organizationId: accessTokenData.organization_id,
    adultUser: refreshTokenData.adult_user,
    expiresAt: refreshTokenData.exp,
    accessTokenExpiresAt: accessTokenData.exp
  };
};

export const getSessionDataFromAccessToken = (accessToken: string): Partial<IAuthSession> => {
  const accessTokenData = jwtDecode(accessToken) as AccessTokenData;
  return {
    role: accessTokenData.role,
    userId: accessTokenData.user_id,
    workspaceId: accessTokenData.workspace_id,
    organizationId: accessTokenData.organization_id,
    accessTokenExpiresAt: accessTokenData.exp
  };
};

const safeParse = <T>(json: string): T | undefined => {
  try {
    return JSON.parse(json) as T | undefined;
  } catch (e) {
    return undefined;
  }
};

export const getLegacyTokens = (): AuthTokens | undefined => {
  if (typeof window === "undefined") return;
  const tokensJson = localStorage.getItem("statlegend");
  if (!tokensJson) return;
  return safeParse<AuthTokens>(tokensJson);
};

export const getAuth = (): IAuthSession | undefined => {
  if (typeof window === "undefined") return;
  const tokensJson = localStorage.getItem("statlegend");
  const sessionJson = localStorage.getItem("session");
  if (!tokensJson && !sessionJson) return;

  if (!tokensJson && sessionJson) {
    return JSON.parse(sessionJson);
  }

  const tokens = tokensJson ? safeParse<AuthTokens>(tokensJson) : undefined;

  return tokens ? getSessionFromTokens(tokens) : undefined;
};

export const saveAuthSession = (tokens: AuthTokens): IAuthSession => {
  const session = getSessionFromTokens(tokens);
  localStorage.setItem("session", JSON.stringify(session));
  return session;
};

export const replaceSessionAccessToken = (accessToken: string): IAuthSession | undefined => {
  const tokensJson = localStorage.getItem("statlegend");
  const sessionJson = localStorage.getItem("session");
  if (tokensJson && !sessionJson) {
    const tokens = safeParse<AuthTokens>(tokensJson);
    if (!tokens) return;
    localStorage.removeItem("statlegend");
    return saveAuthSession({
      refreshToken: tokens.refreshToken,
      accessToken
    });
  }
  if (!sessionJson) return;
  const session = JSON.parse(sessionJson);
  const newSessionData = getSessionDataFromAccessToken(accessToken);
  const newSession = { ...session, ...newSessionData };
  localStorage.setItem("session", JSON.stringify(newSession));
  return newSession;
};

export const refresh = async (): Promise<IAuthSession | undefined> => {
  const currentSession = getAuth();
  if (!currentSession) return;

  const organizationId = currentSession.organizationId;
  const response = organizationId
    ? await V1Service.v1UserManagementTokenOrganizationRefreshCreate({ organizationId })
    : await V1Service.v1UserManagementTokenRefreshCreate();

  return replaceSessionAccessToken(response.access_token);
};

export const logout = async (): Promise<void> => {
  try {
    localStorage.removeItem("metadata-store-persist");
    localStorage.removeItem("order-form-store-persist");
    localStorage.removeItem("statlegend");
    localStorage.removeItem("session");
    await V1Service.v1UserManagementLogoutCreate();
  } catch (e) {
    console.error(e);
  }
};

export const navigateOnUnauthenticated = async (): Promise<void> => {
  if (typeof window === "undefined") return;
  const isOnCreatePage = window.location.pathname === "/create";
  if (isOnCreatePage) {
    window.location.reload();
    return;
  }
  window.location.replace("/login");
};
