import type { NextPage } from "next";
import { AppProps } from "next/app";
import { ReactNode } from "react";
import "../styles/globals.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "../contexts/AuthProvider";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import { googleAnalyticsId, googleTagManagerId, metaPixelId } from "@/utils/env";
import Head from "next/head";
import MetaPixel from "@/components/pixel/meta-pixel";

export const queryClient = new QueryClient();

export type NextPageWithLayout<T = {}> = NextPage<T> & {
  getLayout?: (page: JSX.Element) => ReactNode;
};

type IWebAppProps = AppProps & {
  Component: NextPageWithLayout;
};

const CustomerPortal = ({ Component, pageProps }: IWebAppProps) => {
  return (
    <>
      <Head>
        <title>Stat Legend</title>
        <meta name="description" content="Your game. Your cards. Your legend." />
        <meta name="facebook-domain-verification" content="11m0f5w7vs4bqrq98vxmx91akpdla4" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Component {...pageProps} />
        </AuthProvider>
      </QueryClientProvider>
      <GoogleAnalytics gaId={googleAnalyticsId} />
      <GoogleTagManager gtmId={googleTagManagerId} />
      <MetaPixel pixelId={metaPixelId} />
    </>
  );
};

export default CustomerPortal;
