import axios from "axios";
import { getAuth, getLegacyTokens, logout, navigateOnUnauthenticated, refresh } from "../auth";
import { isTokenExpired } from "../auth/is-token-expired";

export const axiosPrivate = axios.create({
  baseURL: "",
  withCredentials: true
});

let isRefreshing = false;
let failedQueue: { resolve: () => void; reject: (error: any) => void }[] = [];

const processQueue = (error: any) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    }
    prom.resolve();
  });

  failedQueue = [];
};

const sessionIsExpired = () => {
  const auth = getAuth();
  return auth?.expiresAt && isTokenExpired(auth.expiresAt);
};

const accessTokenIsExpired = () => {
  const auth = getAuth();
  return auth?.accessTokenExpiresAt && isTokenExpired(auth.accessTokenExpiresAt);
};

axiosPrivate.interceptors.request.use(
  async (config) => {
    if (sessionIsExpired()) {
      await logout();
      navigateOnUnauthenticated();
      throw new axios.Cancel("Session expired. Re-authenticating...");
    }

    const auth = getAuth();
    const tokens = getLegacyTokens();
    const needsRefreshToken = [
      "user-management/token",
      "guest-user-registration-complete",
      "account-ops/guest/login",
      "account-ops/guest/workspace"
    ].some((url) => config.url?.includes(url));
    const refreshToken = tokens?.refreshToken ?? "{refresh_token}";
    const token = needsRefreshToken ? refreshToken : "{access_token}";
    const tokenPrefix = needsRefreshToken ? "Refresh" : "Bearer";

    if (token && auth) {
      config.headers["Authorization"] = `${tokenPrefix} ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const exemptFromRefresh = [
      "/api/v1/user-management/login",
      "/api/v1/account-ops/guest/login"
    ].some((url) => originalRequest?.url === url);
    const isRefreshAttempt =
      originalRequest?.url?.includes("user-management/token") &&
      originalRequest?.url?.includes("refresh");
    const isLogoutAttempt = originalRequest?.url === "/api/v1/user-management/logout";

    if (isLogoutAttempt) {
      return;
    }

    if (error.response?.status === 403 && isRefreshAttempt) {
      await logout();
      navigateOnUnauthenticated();
      return;
    }

    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      accessTokenIsExpired() &&
      !originalRequest._retry &&
      !exemptFromRefresh
    ) {
      if (isRefreshing) {
        return new Promise<void>((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            originalRequest.headers["Authorization"] = "Bearer {access_token}";
            return axiosPrivate(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        refresh()
          .then(() => {
            axios.defaults.headers.common["Authorization"] = "Bearer {access_token}";
            processQueue(null);
            resolve(axiosPrivate(originalRequest));
          })
          .catch((err) => {
            processQueue(err);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosPrivate;
